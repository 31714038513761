import React, { useEffect, useState } from "react"
import ModalWindow from "../ModalWindow"
import closeGray from "../../images/header/close-gray-icon.svg"
import moment from "moment"
import LoadingPage from "../LoadingPage"
import { useTranslation } from "react-i18next"
import { connect } from "react-redux"
import { setError } from "../../redux/actions/errorHandlerActions"
import { getCreditCardToken, getPaymentStatus } from "./orderCrud"

function TranzilaWindow({
  show,
  src,
  saveCard,
  onClose,
  setError,
  searchUser
}) {
    const { t, i18n } = useTranslation("main");

  const [loadCount, setLoadCount] = useState(0)
  const [firstInitLoading, setFirstInitLoading] = useState(false)

  useEffect(() => {
    if (show) {
      setFirstInitLoading(false);
      setLoadCount(0)
    }
  }, [show])

  useEffect(() => {
    if (loadCount > 1) {
      // Wait for response for 20 seconds
      let duration = moment.duration(30000, "milliseconds")
      // Every 4 seconds send request to get result
      let interval = 4000
      const intervalRef = setInterval(function () {
        duration = moment.duration(duration - interval, "milliseconds")
        getCreditCardToken(searchUser.UserID).then(({ data }) => {
          if (
            data.tranzilaTK 
          ) {
            // Save new card
            saveCard(data.tranzilaTK, data.expmonth, data.expyear)
            // No further need in requests
            clearInterval(intervalRef)
            if (data.tranzilaTK) {
              onClose()
            } else {
              setError(new Error(), t("order.errors.failed_to_add_card"))
            }
          } else {
            setError(new Error(), t("order.errors.failed_to_add_card"))
          }
        })
        .catch(error => {
            setError(error, error?.response?.data?.Message);
        })
        // If there is no result after 10 seconds
        if (duration <= 0) {
          clearInterval(intervalRef)
          // Set iframe back to form page
          const iframe = document.getElementById("tranzila-iframe")
          iframe.src = src
          setLoadCount(0)

          // Show a message that request has failed
          setError(new Error(), t("order.errors.failed_to_add_card"))
        }
      }, interval)
    }
  }, [loadCount])

  return (
    <ModalWindow show={show}>
      <img
        className="confirmation-window-close-icon"
        src={closeGray}
        alt="Close icon"
        onClick={onClose}
      />
      <div className="tranzila-wrapper">
        <iframe
          title="tranzila-iframe"
          id="tranzila-iframe"
          width="100%"
          height="100%"
          src={src}
          allow="payment"
          allowPaymentRequest="true"
          style={{ visibility: loadCount !== 1 && !firstInitLoading  ? "hidden" : "visible" }}
          onLoad={(data) => {
            setLoadCount(loadCount + 1);
              if (!firstInitLoading) {
                setFirstInitLoading(true);
              }
            }
          }
          sandbox="allow-same-origin allow-modals allow-forms allow-popups allow-scripts"
        ></iframe>
        {loadCount !== 1 && !firstInitLoading && <LoadingPage />}
      </div>
    </ModalWindow>
  )
}

function mapStateToProps(state) {
  return {
    searchUser: state.search.searchUser,
    token: state.login.token.Token
  }
}

const mapDispatchToProps = {
  setError
}
export default connect(mapStateToProps, mapDispatchToProps)(TranzilaWindow)
